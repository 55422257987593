import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenfranco/Documents/dev/medgrocer/pp-jn-website/src/components/Layout/Layout.js";
import Container from "layout/Container";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Consent and Authorization" mdxType="SEO" />
      <h2>{`Consent and Authorization`}</h2>
      <hr />
      <div className="content is-medium">
        <h3>{`Data Privacy`}</h3>
        <ul>
          <li parentName="ul">{`The above information is correct and I give Johnson & Johnson Philippines Inc. (“J&J Philippines”) and MedGrocer the authority to collect, store, and use my information for the CarePlus Program (“Program”) and its related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the Program without any further consent needed from my end.`}</li>
          <li parentName="ul">{`J&J Philippines and MedGrocer acknowledge and respect the privacy of individuals.`}</li>
          <li parentName="ul">{`J&J Philippines has engaged MedGrocer to collect and hold my information in order to organize my participation in the Program and to communicate with me regarding the Program. By completing this form and providing the information requested, I consent to J&J Philippines, MedGrocer, and their partners using that information for the purposes outlined. J&J Philippines and MedGrocer may access and disclose the information to respond to regulatory authorities or if required by law.`}</li>
          <li parentName="ul">{`I am not obliged to provide the information and I may choose not to. If I do not provide this information, J&J Philippines and MedGrocer may not be able to provide the needed support to me under the Program or administer the Program effectively or at all.`}</li>
          <li parentName="ul">{`If I would like to access, update, or correct my information or if I do not want my information retained on the database, I should contact MedGrocer.`}</li>
        </ul>
        <h3>{`Program End`}</h3>
        <ul>
          <li parentName="ul">{`I understand that J&J Philippines and MedGrocer have the right to terminate the Program prior to the end date and their representative may retrieve all the remaining communication materials.`}</li>
        </ul>
        <h3>{`Waiver`}</h3>
        <ul>
          <li parentName="ul">{`I will indemnify and hold J&J Philippines, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Program. I also acknowledge that J&J Philippines or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of:`}<ol parentName="li">
              <li parentName="ol">{`any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and`}</li>
              <li parentName="ol">{`any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.`}</li>
            </ol></li>
        </ul>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      